body {
    background: #eeeeee;
    margin-top: 2rem;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  .newBumbskList-container {
    font-family: sans-serif;
    text-align: center;
    width: clamp(360px,100%,840px);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 120px;
  }
  .logo {
    width: 200px;
    margin-bottom: 2rem;
  }
  
  .spin {
    animation: rotation 2s infinite linear;
  }
  .switch-users-button {
    height: 50px;
    width: 160px;
        
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;

    border: 3px solid;
    border-radius: 10px;
    cursor: pointer;

    display: none;
  }
  .Card {
    height: 180px;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    position: relative;
    width: clamp(300px, 96%,640px);
    margin: auto;
}

  #Kannski {
    color: lightgreen;
  }
  #VilEkki {
    color: #e91e63;
    /* background-color: #e91e63; */
  }
  #unanswered {
    color: #e2e2e2;
  }
  
  #match-kannski {
    background-color: lightgreen;
    border: 5px solid black;
  }
  #match-vilekki {
    background-color: #b9144b;
    border: 5px solid black;
  }

  #partner-has-answered {
    width: 80px;
    height: 80px;
    /* border: 4px solid rgb(249, 91, 249); */
    border: 4px solid black;
  }
  #partnerAnswered-youNot {
    width: 80px;
    height: 80px;
    border: 4px solid orangered;
  }
  #waiting-for-you {
    color: orangered;
  }

  
  .Card .imyselfTimeStamp {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: #e91e63; */
    background-color: black;
    padding: 0.2rem;
    color: white;
    font-weight: 800;
    border-radius: 4px 4px 4px 0;
    color: yellow;
    font-size: clamp(12px,3vw,16px);
  }
  .Card .partnerTimeStamp {
    position: absolute;
    top: 0;
    right: 0;
    /* background-color: #e91e63; */
    background-color: black;
    padding: 0.2rem;
    color: white;
    font-weight: 800;
    border-radius: 4px 4px 4px 0;
    color: yellow;
    font-size: clamp(12px,3vw,16px);
  }

  .Card .imyselfAnswer {
    position: absolute;
    /* top: 30%; */
    top: calc(50% - 25px);
    left: 10px;
    /* background-color: #e91e63; */
    background-color: black;
    padding: 0.2rem;
    font-weight: 800;
    color: #e2e2e2;

    border-radius: 4px 4px 4px 0;
    cursor: pointer;
  }
  #CheckNewStatus {
    position: absolute;
    /* top: 30%; */
    top: 10%;
    left: 10px;
    /* background-color: #e91e63; */
    background-color: black;
    padding: 0.2rem;
    font-weight: 800;
    color: #e2e2e2;

    border-radius: 4px 4px 4px 0;
    cursor: pointer;
  }
  /* .Card .answer-options {
    left: calc(50% - 60px);
    
    margin-top: 50px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  } */
  
  
  
  #CheckNewStatus-span  {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 800;
    align-items: center;
    justify-content: center;

    width: 50%;
    height: 40px;
    
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .option-Kannski {
    color: rgba(144, 238, 144, 0.99);
    /* background-color: rgba(144, 238, 144, 0.65); */
    background-color: rgba(0, 128, 0, 0.55);
    font-size: 36px;

    border: 2px solid rgba(144, 238, 144, 0.5);
    border-radius: 5px;
  }
  .option-Kannski.ClickDOWN {
    opacity: 0.25;
  }

  .option-VilEkki {
    color: rgba(255, 68, 0, 0.99);
    background-color: rgba(255, 68, 0, 0.65);
    border: 2px solid rgba(255, 68, 0, 0.74);
    border-radius: 5px;
    
    font-size: 50px;
    padding-bottom: 10px !important;
  }
  .option-VilEkki.ClickUP {
    opacity: 0.25;
  }

  .Card .partnerAnswer {
    position: absolute;
    /* top: 30%; */
    top: calc(50% - 25px);
    right: 10px;
    /* background-color: #e91e63; */
    background-color: black;
    padding: 0.2rem;
    color: white;
    font-weight: 800;
    border-radius: 4px 4px 4px 0;
  }

  #waiting-for-me {
    position: absolute;
    top: 30%;
    right: 10px;
    color: orangered;
    background-color: orangered;
    opacity: 1;
  }
  #cover-over {
    position: absolute;
    top: 30%;
    right: 10px;
    color: black;
    font-size: clamp(20px,7vw,30px);
    background-color: orangered;
    opacity: 1;
}
  
  #hide-now {
    opacity: 0;
  }

  .hide-now {
    opacity: 0;
    display: none;
  }

  #vantar-svar {
    background-color: orangered;
    color: black;
  }
  
  .Card .answer-LARGE {
    font-size: 2.0rem;
    font-weight: 800;
    color: #e2e2e2;
  }
  #answer-LARGE-Kannski {
    color: darkgreen;
  }

  #answer-LARGE-VilEkki {
    color: orangered !important;
  }
  .VilEkki-nafn {
    color: orangered !important;
  }

  /* #MIDDLE-name-show,
  #FIRST-name-show {
    opacity: 1;
    font-size: 1.5rem;
    font-weight: 800;
    color: #e2e2e2;
    height: 35px;
  }
  #MIDDLE-name-hide,
  #FIRST-name-hide {
    opacity: 0;
  } */


  #name-location-show {
    font-size: 1.5rem;
    font-weight: 800;
    color: #e2e2e2;
    height: 35px;
  }
  #name-location-hide {
    color: transparent;
    display: none;
  }

  .location-label {
    color: black;
    font-size: 16px;
  }
  .location-value {
    font-size: 20px;
    font-weight: 800;
  }
  #location-yes {
    color: green;
  }
  #location-no {
    color: orangered;
  }
  .status {
    display: flex;
    flex-direction: column;
    width: clamp(70px,20%,120px);
    height: 50px;
    justify-content: center;
    align-items: center;
    font-size: clamp(11px,3vw,16px);    
  }
  
  .Card img {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    display: none;
  }

  h2 {
    font-size: clamp(25px,7vw,48px);
    font-weight: 800;
  }
  h2:hover {
    cursor: pointer;
  }
  .newName {
    text-transform: uppercase;
    font-size: clamp(25px,7vw,52px);
    font-weight: 900;
    /* text-shadow: 3px 2px black; */
    /* color: white !important; */
    color: rgb(253,93,253) !important;
    text-shadow: 3px 2px rgba(0, 0, 0, 0.651) !important
  }
  .baunanafn {
    width: 50%;
    margin: auto;
    font-size: clamp(30px,7vw,48px);
  }
  .baunanafn-Kannski {
    color:#b9144b;
  }
  .baunanafn-VilEkki {
    color: green;
  }
  .yellowStyle {
    color: yellow !important;
    background-color: black;
    padding: 5px 10px;
    border-radius: 10px;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  