.WindowSize {
  width: 100vw;
  height: 100vh;
}
.App {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-box {
  position: fixed;
  display: flex;
  flex-direction: column;
  color: pink;
  padding-top: 40px;
  padding-bottom: 140px;
  padding-inline: 10% 10%;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  top: 0px;
  background: black;
  height: 200px;
  width: 100vw;
}
.search-row {
  display: grid;
  grid-template-columns: 1fr clamp(260px, 90%, 400px) 30px 1fr;
  justify-self: center;
  padding-inline: 30px;
  padding-block: 80px 100px;
  align-items: center;
  width: clamp(360px, 80vw, 1000px);
  position: fixed;
  top: -10px;
  margin-bottom: 10px;
}

.search-input-label  {
  font-size: clamp(18px, 4vw, 30px);
  width: 0%;
  padding-top: 5px;
  padding-right: 0px;
  opacity: 0;
}
.search-input {
  font-size: large;
  padding-left: 10px;
  width: clamp(200px,100%,400px);
  height: 35px;
  background-color: pink;
  color: black;
  font-weight: 600;
  margin: auto;
}
#search-input {
  background-color: pink;
}
.clear-button {
  width: 30px;
  height: 30px;
  color: white;
  opacity: 0.5;
  padding-inline: 10px;
  align-self: center;
  font-size: 20px;
  font-weight: 600;
}
.clear-button:hover {
  cursor: pointer;
}
.text-to-erase {
  color: red;
  opacity: 1;
}
.container {
  width: clamp(360px, 80vw, 1000px);
  margin: auto;
  overflow-x: hidden;
  /* padding-inline: 5vw 10vw; */
}


.result-row {
  opacity: 0;
}

.filtered.statlabel {
  font-size: 20px;
  font-weight: 600;
  padding-right: 5px;
}
.statlabel {
  font-size: 30px;
  font-weight: 700;
  padding-right: 5px;
}
.filtered.statnumber {
  font-weight: 600;
}


.grid {
  display: grid;
  grid-template-columns: 35% clamp(50px,7vw,75px) clamp(50px,7vw,75px) 1fr;
}
/* .headerlina:nth-child(1) {width: 35%;}
.headerlina:nth-child(2) {width: 70px;}
.headerlina:nth-child(3) {width: 70px;} */

.stats {
  width: 100%;
  display: grid;
  grid-template-rows: 2fr 1fr;
  /* gap: 5px; */
  justify-content: center;
  align-items: center;

  background-color: rgba(3,3,3,0.9);
  color: whitesmoke;
  font-size: clamp(12px, 4vw, 20px);
  border-radius: 10px;

  margin-inline: auto;
  position: sticky;
  top: 120px;
  /* top: 170px; */
  z-index: 10;
  box-sizing: border-box;
}

.stats-item {
  padding-inline: 5px;
  padding-block: 5px;

  margin-inline: 5px;
  
  justify-content: center;
  align-items: center;
}

#hide-list {
  color: rgba(245,245,245, 0.35);
  /* background-color: rgb(68 203 132 / 35%); */
  background-color: rgb(245 245 245 / 15%);
  border: 2px solid rgb(245 245 245 / 35%);
}

.filt-and-total {
  width: 100%;
  height: 100%;
  display: flex;
}
.stats-filtered {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: rgb(253,93,253);
  color: black;
  align-items: center;
  padding-inline: 4%;
  border: 2px solid;
  width: 50%;
}
.stats-total {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 50%;
  
  background-color: rgb(56 120 241 / 75%);
  color: white;
  font-weight: 600;

  box-sizing: border-box;
}

.stats-maybe {
  /* background-color: #2196F3; */
  background-color: rgb(68 203 132 / 95%);
  color: white;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.stats-not {
  background-color: #bd0a0a;
  color: white;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-inline: 20px;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.stats-rest {
  background-color: rgba(255, 255, 0,0.75);
  color: black;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 80%;
  justify-self: end;
  align-self: baseline;
  cursor: pointer;
  justify-content: end;
}
.rest-stats-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: rgba(255, 255, 0,0.75);
  height: 100%;
  margin-left: 6px;
}
.stats-filtered-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: rgb(253,93,253);
  height: 100%;
  width: 70%;
  padding-right: 5px;
}
.stats-percentage {
  font-size: clamp(10px,2vw,12px);
  font-weight: 400;
  font-style: italic;
  width: 100%;
  text-align: center;
  padding-inline: 0px;
  margin-inline: 0px;
}
.stats-label {
  font-size: clamp(9px, 2vw, 18px);
  font-weight: 400;
}
.maybe-label {
  font-size: clamp(9px, 2vw, 18px);
}
.rest-label {
  align-self: baseline;

  font-size: clamp(13px, 4vw, 25px);
  font-weight: 700;
  text-transform: uppercase;
  font-style: italic;
}

.stats-number {
  font-size: clamp(15px, 4vw, 25px);
  font-weight: 700;
  padding-inline: 5px;
  margin-inline: 5px;
}
.filtered-number {
  font-size: clamp(15px, 3vw, 25px);
  font-weight: 500;
}
#filtered-label {
  font-size: clamp(20px, 3vw, 30px);
  font-weight: 700;
  background-color: black;
  color: rgb(253,93,253);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  width: 30%;
}
#total-label {
  font-size: clamp(8px, 2vw, 16px);
  margin-bottom: 2px;
}
#total-number {
  font-size: clamp(10px, 3vw, 20px);
}
table {
  border: 2px solid blueviolet;
  background-color: rgba(0,0,0,0.25);
  width: 100%;
  opacity: 1;
  margin-top: 90px;
}
/* table:hover {
  opacity: 1;
} */
thead {
  background-color: pink;
}
th {
  text-align: left;
  position: sticky;
  top: 190px;
  /* top: 240px; */
  background-color: pink;
  padding-block: 10px;
  z-index: 10;
  border-bottom: 3px solid black;
  font-size: clamp(12px, 4vw, 25px);
}
/* th:nth-child(2),th:nth-child(3)  */
.checkbox-header {
  text-align: center;
  font-size: clamp(12px, 4vw, 20px);
  font-weight: 400;
  border-top: 3px solid black;
  color: pink;
}
#name-header {
  background-color: black;
  color: whitesmoke;
  padding-left: 10px;
  border-bottom: 3px solid black;
  border-top: 3px solid pink;
  border-left: 3px solid pink;
  box-sizing: border-box;
  width: 35%;
}
#maybe-checkbox-header {
  /* background-color: #2196F3; */
  background-color: rgb(68 203 132 / 95%);
  font-size: clamp(9px, 2vw, 18px);
  width: clamp(50px,7vw,75px);
}
#not-checkbox-header {
  background-color: #bd0a0a;
  width: clamp(50px,7vw,75px);
}
#comment-header {
  background-color: black;
  color: whitesmoke;
  padding-left: 10px;
  /* border: 3px solid pink; */
  border-bottom: 3px solid black;
  border-top: 3px solid pink;
  border-right: 3px solid pink;
  box-sizing: border-box;
  font-size: clamp(10px, 3vw, 20px);
  cursor: pointer;
}
#Comment-Count {
  color: yellow;
  font-weight: 600;
}
.only-comments-white {
  color: black !important;
  font-weight: 700 !important;
  font-size: clamp(12px, 3vw, 25vw) !important;
  background-color: white !important;
}
.smallfont {
  font-size: clamp(10px, 3vw, 18vw) !important;
}
.Show-Only-Comments {
  color: black !important;
  /* background-color: rgb(65 219 65) !important; */
  background-color: white !important;
}

#aKannski {
  background-color: rgb(65 219 65);
  border: 3px solid black !important;
}
#sammalaKannskiLina {
  color: black !important;
  border: 3px solid black !important;
  background-color: rgb(65 219 65) !important;
}
.sammalaPlus-Lina {
  color: black !important;
  border: 3px solid black !important;
  background-color: rgb(65 219 65) !important;
}
#sammalaKannskiLina > #not-box > span {
  background-color: transparent;
}
#sammalaKannskiLina #not-box > span {
  background-color: transparent;
}

#sammalaEkkiLina {
  color: black !important;
  border: 3px solid black !important;
  background-color: rgb(195 8 8);
}
.sammalaEkkiLina {
  color: black !important;
  border: 3px solid black !important;
  background-color: rgb(195 8 8) !important;
}
#sammalaEkkiLina #kannskiBox {
  background-color: transparent;
}
#sammalaEkkiLina > * {
  color: black;
  border: 3px solid black !important;
  background-color: rgb(195 8 8);
}
#sammalaKannskiLina > * {
  color: black;
  border: 3px solid black !important;
}
#aEkki {
  color: rgb(0 0 0 / 70%);
  background-color: rgb(195 8 8);
}
/* span fyrir Sammála um EKKI */
#sammalaEkki {
  border: 3px solid rgb(195 8 8);
  color: #bd0a0a;
  background-color: black;
}
#sammalaKannski {
  background-color: rgb(65 219 65);

  margin-left: -10%;
  margin-right: -5px;
  margin-top: 0%;
  margin-bottom: 0%;
  
  font-size: 30px;

  height: 45px;
  width: clamp(40px, 100%,60px);
}
/* .nafnalina {
  display: grid;
  grid-template-columns: 35% 70px 70px 1fr;
} */
.nafnalina:nth-child(2n-1) {
  background-color: rgba(138, 43, 226, 0.25);
  /* color: pink; */
  color: whitesmoke;
}
.nafnalina:nth-child(2n-1) * {
  color: whitesmoke;
}
.nafnalina:nth-child(2n-1):hover > *,
.nafnalina:nth-child(2n-1):hover * {
  color: yellow;
}
/* .nafnalina:nth-child(2n-1):hover {
  background-color: rgba(138, 43, 226, 0.5);
  color: pink;
} */

.nafnalina:nth-child(2n) {
  background-color: rgb(255 192 203 / 100%);
  color: blueviolet;
}
.nafnalina:nth-child(2n-1) .stafur:hover,
.nafnalina:hover {
  background-color: black;
  /* color: rgb(193,93,193); */
  color: yellow !important;
  border: 2px solid yellow;
}
/* .nafnalina:nth-child(2n):hover {
  background-color: pink;
} */

.stafur {
  color: blueviolet;
  padding-left: 5px;
  padding-block: 5px;
  font-size: clamp(18px, 4vw, 30px);
}

.checkbox {
  color: blue;
}

.kannski {
  cursor: pointer;
}
.kannski.clicked {
  background-color: green;
}


.checkbox-wrap {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  text-align: center;
  user-select: none;
  margin: auto;
}
#maybe-box,
#not-box {
  color: transparent;
}

/* Hide the browser's default checkbox */
.checkbox-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  
  height: 40px;
  width: clamp(40px, 100%,60px);

  display: flex;
  justify-content: center;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-wrap:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-wrap input:checked ~ .checkmark {
  background-color: #2196F3;
}
#maybe-box input:checked ~ #sammalaKannski {
  background-color: rgb(65 219 65);
  color: black;
  font-weight: 700;
}
#maybe-box input:checked ~ #sammalaKannski:after {
  border: none;
  color: black;
}
#maybe-box > span {
  background-color: rgb(68 203 132 / 65%);
  width: 100%;
  margin: auto;
  padding: 0px 0px;
  left: -2%;
}
.sammalaEkkiLina #maybe-box > span {
  background-color: rgb(68 203 132 / 5%);
}
#maybe-box > input:checked ~ .checkmark {
  /* background-color: #2196F3; */
  background-color: rgb(68 203 132 / 85%);
}
#not-box > span {
  background-color: #df6363;
  width: 100%;
  margin: auto;
  padding: 0px 0px;
  left: -2%;
  border: none;
}
#not-box > input:checked ~ .checkmark {
  color: #bd0a0a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
#not-box > input:checked ~ .checkmark:after {
  content: "X";
  font-size: clamp(20px, 3vw, 40px);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
#sammalaEkki > #not-box > span {
  background-color: transparent;
}
#sammalaEkki > #not-box > input:checked ~ .checkmark:after {
  content: "(:";
  font-size: 30px;
  font-weight: 700;
  border: 3px solid #bd0a0a;
  margin-top: -8%;
  width: 100%;
  height: 90%;
  padding-bottom: 10%;
}
/* Show the checkmark when checked */
.checkbox-wrap input:checked ~ .checkmark:after {
  display: block;
}



#maybe-box .checkmark:after {
  /* left: 35%;
  top: 5%;
  width: 20%;
  height: 55%;
  border: solid pink;
  border-width: 0 6px 8px 0;
  transform: rotate(35deg); */
  left: clamp(20px,35%,25px);
  top: clamp(4px,8%,25px);
  width: clamp(8px,10%,25px);
  height: clamp(15px,40%,25px);
  border: solid pink;
  border-width: 0 6px 8px 0;
  transform: rotate(35deg);
}

.commentbox {
  width: calc(98% - 10px);
  height: 40px;
  margin-inline: 5px;
  padding-inline: 10px;
  background-color: rgba(245,245,245,0.5);
  border: 3px solid rgba(24,25,24,0.25);
}
.commentbox:nth-child(2n-1) * {
  color: pink;
}
.commentbox:nth-child(2n) * {
  color: rgba(138, 43, 226, 0.5);
}

#svg-baun {
  position: absolute;
  top: 0px;
  left: 0px;

  height: 100vh;
  width: 100vw;

  z-index: -1;
}

.background {
  position: absolute;
  top: 0px;
  left: 0px;

  height: 100vh;
  width: 100vw;

  z-index: -1;
}
#bg-svg {
  width: 100%;
  height: 100%;
}
.SVG-BG-TEST {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-image: url(../img/BumbubaunBackground.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  margin: auto;
}
.userinfo-wrap {
  position: fixed;
  top: 10px;
  left: calc(50vw - 75px);
  z-index: 100;
  opacity: 0.95;
}
.userinfo-wrap:hover {
  opacity: 0.95;
  font-weight: 700;
}
.UserInfo {
  position: sticky;
  top: 10px;
  left: calc(50vw - 75px);
  z-index: 100;
  font-size: 20px;
  /* cursor: pointer; */
  user-select: none;
  color: rgb(193,93,193);
  border: 2px solid rgb(193,93,193);
  border-radius: 10px;
  text-transform: uppercase;
  width: 150px;
  padding-block: 10px;
  margin: auto;
  text-align: center;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

#BALDVIN {
  color: yellow;
  border: 2px solid yellow;
}
#AGLA {
  border: 2px solid rgba(245,245,245,0.25);
  background-color: rgb(193,93,193);
  color: white;
  font-weight: 200;
  font-size: 30px;
}
/* #AGLA {
  border: 2px solid black;
  background-color: rgba(255, 255, 0, 0.6);
  color: black;
  font-weight: 400;
  font-size: 30px;
}
#AGLA:hover {
  background-color: rgba(255, 255, 0, 0.95);
  color: black;
  font-weight: 700;
} */