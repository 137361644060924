.dashboard {
    width: 100%;
    justify-content: center;
    align-items: center;
  
    background-color: rgba(3,3,3,0.9);
    color: whitesmoke;
    font-size: clamp(12px, 4vw, 20px);
    border-radius: 10px;
  
    margin-inline: auto;
    position: sticky;
    top: 120px;
    z-index: 10;
    box-sizing: border-box;
  }
.Row {
    width: 96%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px,1fr));
    gap: clamp(5px, 1vw, 10px);
    justify-content: center;
    align-items: center;
    margin-inline: 2%;
}
.Row.MatchStats {
    /* display: none; */
    position: relative;
    bottom: 30px;
}
.statbox-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

}
.myStats {

}
.SammalKannski {
    color: green;
}

.SAMMALA-ICON {
    font-size: clamp(34px,3vw,44px);
    font-weight: 800;
}
.SAMMALA-LABEL {
    font-size: clamp(10px,3vw,20px);
}
#SAMMALA-LABEL-Kannski {

}
#NAME-COUNT-Kannski,
#SAMMALA-ICON-Kannski {
    color: lightgreen;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 40px;
}

.SammalVilEkki {
    color: rgb(255 69 0 / 50%);
}
.NAME-COUNT {
    font-size: clamp(20px,3vw,34px);
}
#NAME-COUNT-VilEkki,
#SAMMALA-ICON-VilEkki {
    color: orangered; 
    display: flex;
    align-items: center;
}

.BidurSvars {
    font-size: clamp(10px,3vw,14px);
}
#dummy-fake-box {
    opacity: 0;
}

/* .stats-filtered-numbers { */
.dashboard-stats-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    height: 100%;
    width: 100%;
    padding-inline: 3px;
    cursor: pointer;
}
#filtered-number {
    font-size: clamp(16px, 3vw,30px);
    font-weight: 600;
}
/* .ShowHideSpan  {

    background-color: black;
    color: rgba(245, 245, 245, 0.65);

    border: 1px solid rgba(244, 244, 244, 0.5);
    border-radius: 5px;
    font-size: clamp(8px,3vw,12px);
    padding: 2px 10px;
    position: relative;
    top: 5px;
    font-weight: 500;
    font-style: italic;
    
} */
.show-hide-spans {
    display: none;
}
.ShowHideSpan {
    background-color: black;
    color: rgba(245, 245, 245, 0.65);
    /* border: 1px solid rgba(2, 2, 2, 0.5); */
    border: 1px solid rgba(244, 244, 244, 0.5);
    border-radius: 5px;
    font-size: clamp(8px,2vw,15px);
    padding: 2px 10px;
    position: absolute;
    top: clamp(52px,95%,65px);
    margin-left: clamp(-65px,-7%,-25px);
    font-weight: 500;
    font-style: italic;
    width: clamp(50px,6vw,80px);
    text-align: center;
}
#hide-ShowHideSpan {
    color: rgba(245, 245, 245, 0.65);
    background-color: rgba(22, 2, 2, 0.75);
    border: 1px solid rgba(244, 244, 244, 0.5);
    text-shadow: 0 0 black;
    font-weight: 100;
}
.result-span {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-stats-number {
    font-size: clamp(12px, 4vw, 18px);
    font-weight: 700;
    text-align: center;
    padding-left: 5px;
}

.dashboard-stats-percentage {
    font-size: clamp(11px, 3vw, 14px);
    font-weight: 400;
    font-style: italic;
    text-align: center;
}

.dashboard-search-results {
    background-color: rgb(253,93,253);
    color: black;
    border: 2px solid black;
    border-radius: 5px;
}
.dashboard-Answered-results {
    border: 2px solid rgb(244 243 242 / 55%);
    border-radius: 5px;
}
.dashboard-percentage-Answered {
    font-size: 12px;
}
.dashboard-Kannski-results {
    color: lightgreen;
    font-size: clamp(16px,4vw,22px);
    font-weight: 600;
    border: 2px solid rgb(68 203 132 / 55%);
    border-radius: 5px;
}
.dashboard-Kannski-results.show-only-SELECTED {
    color: lightgreen;
    background-color: rgb(1, 59, 1);
    border: 2px solid rgb(68 203 132 / 75%);
}
.stat-Kannski-alt  {
    background-color: black;
    color: rgb(144 238 144);
    font-size: clamp(16px,4vw,22px);
    font-weight: 300;
    border: 2px solid rgb(144 238 144 / 55%);
    border-radius: 5px;
}
.stat-VilEkki-alt  {
    background-color: black;
    color: orangered;
    font-size: clamp(16px,4vw,22px);
    font-weight: 400;
    border: 2px solid #ff450091;
    border-radius: 5px;
}
.dashboard-VilEkki-results {
    background-color: black;
    color: orangered;
    font-size: clamp(16px,4vw,22px);
    font-weight: 600;
    border: 2px solid rgb(255 69 0 / 55%);
    border-radius: 5px;
}
.dashboard-TheRest-results {
    background-color: black;
    color: rgb(253,93,253);
    border: 2px solid rgb(253,93,253);
    border-radius: 5px;
}
.stat-TheRest-alt {
    background-color: black;
    color: rgb(253,93,253);
    border: 2px solid rgb(253 93 253 / 63%);
    border-radius: 5px;
}

.dashboard-number {
    font-size: clamp(15px, 3vw, 22px);
    font-weight: 500;
}
.dashboard-BidurSvars-results {
    color: rgba(255, 255, 0, 0.75);
}

#hide-stat {
    background-color: hsla(0,0%,96%,.15);
    border: 2px solid hsla(0,0%,96%,.35);
    color: hsla(0,0%,96%,.1);
    border-radius: 5px;
}
#hide-stat * {
    /* opacity: 0.5; */
}
#FALINN {

}

#show-only {
    /* background-color: hsla(0,0%,96%,.15);
    border: 2px solid hsla(0,0%,96%,.35); */
    /* color: rgba(255, 255, 0, 0.822); */
    color: lightgreen;
}

#BARA-SammalKannski {
    background-color: rgba(0, 128, 0, 0.22);
    border: 2px solid lightgreen;
    color: lightgreen;
    text-shadow: 1px 1px black;
    border-radius: 5px;
}
#SÝNDUR-SammalKannski {
    /* background-color: rgba(0, 128, 0, 0.15); */
    /* border: 2px solid rgba(255, 255, 0, 0.65); */
    color: rgba(255, 255, 0, 0.65);    
    border-radius: 5px;
}
#FALINN-SammalKannski {
    box-sizing: border-box;
    border-radius: 5px;
    border: 3px solid rgba(0, 128, 0, 0.15);
    color: rgba(245, 245, 200, 0.2);
    font-style: italic;
    background-color: rgba(0, 128, 0,0.3);
}
#FALINN-SammalKannski .SAMMALA-ICON,
#FALINN-SammalKannski .NAME-COUNT {
    color: rgba(0, 128, 0, 0.486);
}
#BARA-TAKKI-SammalKannski {
    border: 2px solid lightgreen;
    color: lightgreen;
}
#BARA-SammalVilEkki {
    background-color: #b9144b;
    border: 2px solid orangered;
    color: orangered;
    border-radius: 5px;
}
#BARA-TAKKI-SammalVilEkki {
    border: 2px solid orangered;
    color: orangered;
}
#BARA-BidurSvars {
    background-color: rgba(255,255,0,0.3);
    border: 2px solid rgba(255, 255, 0, 0.99);
    color: rgba(255, 255, 0, 0.99);
    text-shadow: 1px 1px black;
    border-radius: 5px;
}
#SÝNDUR-BidurSvars {
    border: 2px solid rgba(255, 255, 0, 0.65);
    color: rgba(255, 255, 0, 0.65);
    background-color: transparent;
    border-radius: 5px;
}

#FALINN-BidurSvars {
    box-sizing: border-box;
    border-radius: 5px;
    border: 3px solid rgba(255, 255, 180, 0.15);
    /* color: rgba(255, 255, 0, 0.15); */
    color: rgba(245, 245, 200, 0.2);
    font-style: italic;
    background-color: rgba(255,255,255,0.3);
}
#FALINN-BidurSvars .NAME-COUNT {
    color: rgba(245, 245, 200, 0.1);
}
#BARA-TAKKI-BidurSvars {
    border: 2px solid rgba(255, 255, 0, 0.822);
    color: rgba(255, 255, 0, 0.822);
}

/* #BARA-TAKKI {
    background-color: rgba(244,244,244,0.15);
    border: 2px solid rgba(255, 255, 0, 0.822);
    color: rgba(255, 255, 0, 0.822);
    border-radius: 5px;
} */