/* .sammala-show {
    background-color: black;
    padding: 0.2rem;
    color: white;
    font-weight: 800;
    border-radius: 4px 4px 4px 0;
    width: fit-content;
    margin-inline: auto;
    margin-top: 5px;
    padding-inline: 15px;
    padding-block: 5px;
} */
.sammala-show {
    background-color: black;
    padding: 6px 30px;
    border-radius: 5px;
    font-weight: 800;
    border-radius: 4px 4px 4px 0;
    width: fit-content;
    margin-inline: auto;
    margin-top: -30px;
    padding-inline: 15px;
    padding-block: 5px;
    z-index: 0;
    position: relative;
}
.hide-sammala {
    display: none;
}

.sammalaCARDKannski,
#sammalaCARDKannski {
    background-color: lightgreen;
    /* background-color: yellow !important; */
}
.sammalaCARDVilEkki,
#sammalaCARDVilEkki {
    /* background-color: #ca0e0e; */
    background-color: #b9144b;
}

#sammala-Kannski {
    color: lightgreen;
    padding: 6px 30px;
    border-radius: 5px;
}
#sammala-VilEkki {
    color: rgb(202, 14, 14);
    padding: 6px 30px;
    border-radius: 5px;
}

#username {
    color: whitesmoke;
    font-style: italic;
    font-size: 12px;
}

/* #GREEN-NAME { */
#NAFN-LARGE-Kannski {
    color: green;
    text-shadow: 3px 3px darkgreen;
    text-transform: uppercase;
}
/* #RED-NAME { */
#NAFN-LARGE-VilEkki {
    color: rgb(100, 13, 13);
    text-decoration: line-through;
    font-style: italic;
}

.Card .answer-options {
    left: 35%;
    margin-top: 65px;
    border-radius: 5px;
    width: 30%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.answeroption  {
    display: flex;
    
    font-weight: 800;
    align-items: center;
    justify-content: center;

    width: 50%;
    height: 40px;
    
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
.answerthumbs {
    margin-top: 50px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    cursor: pointer;
}

.thumb-up {
    position: absolute;
    top: 60%;
    left: calc(50% - 80px);
    height: 50px;
    width: 50px;
    /* background-image: url(http://localhost:3000/static/media/ThumbUp.e73f33c….svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
    z-index: 10;
    margin: auto;
    color: black;
    background-color: transparent;
    border: none;
}

.iconthumb {

}
.thumb-color-fill {
    fill: none;
}
.thumb-color-fill.ClickUP {
    fill: lightgreen;
}
.ClickDOWN.thumb-color-fill {
    fill: orangered;
}

.thumb-outline-fill {
    fill: black;
}

#ThumbUp > div > svg > path.thumb-color-fill,
#ThumbDown > div > svg > path.thumb-color-fill {
    fill: none;
}
#clicked-ThumbDown > div > svg > path.thumb-color-fill {
    fill: orangered;
}
#clicked-ThumbUp > div > svg > path.thumb-color-fill {
    fill: lightgreen;
}
#iconthumb-svg {
    width: 50px;
    height: 50px;
}
.thumbup {
    position: absolute;
    top: 60%;
    left: calc(25% - 0px);
    height: 50px;
    width: 50px;
    /* background-image: url(http://localhost:3000/static/media/ThumbUp.e73f33c….svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
    z-index: 10;
    margin: auto;
    stroke: #f14747;
    color: black;
    background-color: transparent;
    border: none;
}
.thumbdown {
    position: absolute;
    top: 75%;
    left: calc(60% + 0px);
    height: 40px;
    width: 40px;
    /* background-image: url(../img/ThumbUp.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
    transform: rotate(180deg);
    z-index: 10;
    margin: auto;
    fill: red;
    color: black;
    background-color: transparent;
    border: none;
}

.TIPEX-HIDE,
#TIPEX-HIDE {
    background-color: rgba(13, 13, 13, 0.5) !important;
    border: 2px solid rgba(245,245,245, 0.5);
}
.TIPEX-HIDE *,
.TIPEX-HIDE #NAFN-LARGE-Kannski {
    color: rgba(100, 13, 13, 0) !important;
    background-color: rgba(100, 13, 13, 0) !important;
    text-shadow: none;
}

/* #TIPEX-HIDE > *,
#TIPEX-HIDE > #NAFN-LARGE-Kannski,
#TIPEX-HIDE * {
    color: rgba(100, 13, 13, 0) !important;
    background-color: rgba(100, 13, 13, 0) !important;
    text-shadow: none;
} */

/* .GREEN-NAME {
    color: green;
    text-shadow: 3px 3px darkgreen;
    text-transform: uppercase;
}
.RED-NAME {
    color: rgb(100, 13, 13);
    text-decoration: line-through;
    font-style: italic;
} */