.thumb-color-fill {
    fill: none;
}
.GREENthumb-outline-fill {
    fill: darkgreen;
}

.GREENthumb-color-fill {
    fill: lightgreen;
}

.REDthumb-outline-fill {
    fill: darkred
}
.REDthumb-color-fill {
    fill: orangered;
}

.iconthumbGreen {
    transform: scale(0.75);
}

.iconthumbRED {
    transform: rotateX(175deg) rotateZ(-10deg) rotateY(150deg);
}
.iconthumbGREEN  {
    transform: rotateX(1deg) rotateY(5deg) rotateZ(4deg) translateY(20px);
}